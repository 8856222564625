.App {
  text-align: center;
  /*font-family: Inria Sans, sans-serif;*/
  font-family: Barlow Semi Condensed, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mobile-drawer {
  position: absolute;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  top: 70% !important;
  height: 100vh;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  pointer-events: auto !important;
}

.line-normal {
  line-height: normal;
}


#aviator > canvas {
    transform: scale(0.5) translate(-50%,-50%);
  /*top: 50%; right: 50%;*/
  /*transform: translate(50%,-50%);*/
  }
#slot-game > canvas {
    transform: scale(0.5) translate(-50%,-50%);
  }
#slot-test > canvas {
    /*transform: scale(0.5) translate(-50%,-50%);*/
  }
.absolute-center{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#machine-frame-svg-second{
  position: absolute;
  top: 0;
  z-index: 100;
  pointer-events: none;
}

/* slot x5/10 button animation*/
#multiply-bnt.active *  {
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-play-state: running;
  offset-rotate: 0deg;
}

.machine-content-safari{
  height: auto!important;
  width: auto!important;
}

@keyframes eaf-e806-13 {
  0%, 100% {
    transform: none;
    offset-path: path("m67.5 16.9996l0 8.4696l0 -8.4696");
  }
}

@keyframes eaf-e806-14 {
  0% {
    offset-distance: 0%;
    animation-timing-function: cubic-bezier(0.88, 0.01, 0.14, 0.99);
  }
  50% {
    offset-distance: 50%;
    animation-timing-function: cubic-bezier(0.88, 0.01, 0.14, 0.99);
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes eaf-e806-15 {
  0%, 100% {
    transform: translate(-67.5px, -16.9996px);
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% { transform: scale(.9); }
  100% { transform: scale(1); }
}

.all-wins-box {
  position: absolute;
  z-index: 100;
  animation: bounce-in 0.5s ease-in;
}